import React from 'react';
import './InputUnderline.scss';

const InputUnderline = () => {
    return (
        <span className="input-underline"></span>
    )
}

export default InputUnderline;
